<template>
    <div class="w-full h-full flex flex-col px-16 pb-16 overflow-y-auto">
        <h1 class="py-8 h-32">{{$t('learnMore.learnHowToGetBetter')}}</h1>
        
        <transition-group 
            v-if="articles"
            appear
            name="slide-up" 
            tag="div"
            class="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-16">
            
            <router-link 
                v-for="(article,index) in articles"
                :key="article.sys.id"
                :to="{ name: 'article', params: { articleId: article.sys.id} }" 
                class="
                    cursor-pointer 
                    flex 
                    panel
                    hover:shadow-2xl 
                    transition 
                    duration-200 
                    ease-in-out
                    slide-up-item
                ">
                
                <img 
                    v-if="article.fields.image && article.fields.image.fields && article.fields.image.fields.file && article.fields.image.fields.file.url"
                    :src="`${article.fields.image.fields.file.url}?w=200&h=200`" 
                    class="mb-4 w-32 h-32 object-cover rounded-lg mr-8 flex-none"
                />
                <div class="flex-grow">
                    <div class="text-black leading-tight mb-1">{{article.fields.heading || ''}}</div>
                    <p>{{article.fields.intro || ''}}</p>
                    <span 
                        v-if="article.fields.author"
                        class="text-sm">
                        {{article.fields.author.fields.name}}
                    </span>
                </div>
            </router-link>
        </transition-group>
    </div>
</template>

<script>
    
    export default {
        computed: {
            articles(){
                return this.$store.getters.getArticles;
            },
        },
        data(){
            return {
                loading: true,
            }
        },
        
        mounted(){
            this.$store.dispatch('listArticles');
        }
    }
</script>
